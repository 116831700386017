import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { graphql } from 'gatsby'
import Disqus from 'disqus-react'
import { Subtitle } from '../elements/Titles'

import PostWrapper from '../views/blog/PostWrapper'
import Footer from '../components/Footer'
// Components
import Layout from '../components/Layout'
import config from '../../config/website'
import ArticleSEO from '../components/ArticleSEO'
import BreadCrumbs from '../components/Breadcrumbs'

const BlogContainer = styled.div`
  ${tw`flex flex-col items-center mt-0 pb-32 justify-start text-color-black text-lg`}
  overflow:auto;
`

const TextWrapper = styled.div`
  ${tw`tracking-normal antialiased subpixel-antialiased font-sans max-w-full text-left px-2`}
`

const DisqusWrapper = styled.div`
  ${tw`pb-32`}
`

const BlogPost = props => {
  const post = props.data.markdownRemark
  const { title, description, image } = post.frontmatter
  const disqusConfig = {
    url: props.location.href,
    identifier: props.location.pathname,
    title,
  }
  const CustomSEO = () => <ArticleSEO title={title} description={description} image={image} />
  return (
    <>
      <Layout location={props.location} CustomSEO={CustomSEO} />
      <PostWrapper>
        <BreadCrumbs location={props.location} />
        <BlogContainer>
          <Subtitle>{title}</Subtitle>
          <TextWrapper dangerouslySetInnerHTML={{ __html: post.html }} />
        </BlogContainer>
        <DisqusWrapper>
          <Disqus.DiscussionEmbed shortname={config.disqus.short_name} config={disqusConfig} />
        </DisqusWrapper>
      </PostWrapper>

      <Footer position="relative" />
    </>
  )
}

export default BlogPost
export const query = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        image
      }
    }
  }
`
