import React from "react";
import Helmet from "react-helmet";
import config from "../../config/website";

const SEO = ({
  title = "",
  description = "",
  author = "Dasith Kuruppu",
  keywords = ["blog", "article"],
  image
}) => {
  const blogURL = config.siteUrl + config.pathPrefix;
  const imageURL = `${blogURL}\images${image}`;
  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: blogURL,
      name: title,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : ""
    }
  ];
  return (
    <Helmet>
      <html lang={config.siteLanguage} />
      <title>{title}</title>
      <meta charset="UTF-8" />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.concat(",")} />
      <meta name="author" content={author} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link
        rel="icon"
        type="image/png"
        sizes="128x128"
        href="/favicons/favicon-128x128.png?v=69PX3re3EA"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="64x64"
        href="/favicons/favicon-64x64.png?v=69PX3re3EA"
      />
        <link
        rel="icon"
        type="image/png"
        sizes="48x48"
        href="/favicons/favicon-48x48.png?v=69PX3re3EA"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicons/favicon-32x32.png?v=69PX3re3EA"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicons/favicon-16x16.png?v=69PX3re3EA"
      />
      
      <meta name="dasith-blog" content="Dasith's website/blog" />
      <link rel="manifest" href="/favicons/site.webmanifest?v=69PX3re3EA" />
      <link
        rel="mask-icon"
        href="/favicons/safari-pinned-tab.svg?v=69PX3re3EA"
        color="#5bbad5"
      />
      <link rel="shortcut icon" href="/favicons/favicon.ico?v=69PX3re3EA" />
      <meta name="msapplication-TileColor" content={config.backgroundColor} />
      <meta name="msapplication-config" content="browserconfig.xml" />
      <meta name="image" content={imageURL} />
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
      <meta property="og:locale" content={config.ogLanguage} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={config.ogSiteName} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageURL} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:creator"
        content={config.userTwitter ? config.userTwitter : ""}
      />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageURL} />
    </Helmet>
  );
};

export default SEO;
